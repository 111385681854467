import React from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import loginPicture from '../../images/ES-Login.jpg'
import Triangles from '../../images/blue_triangles.png'

import {
  LoginContainer,
  Messages,
  useMessagesHeight,
  useMessagesQuery
} from '../Login'

const HalfContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'customTop'
})(({ theme, customTop }) => ({
  height: `calc(100vh - ${customTop}px)`,
  position: 'fixed',
  zIndex: 1,
  top: customTop,

  [theme.breakpoints.up('md')]: {
    width: '50%'
  }
}))

export const Left = styled(HalfContainer)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('md')]: {
    display: 'initial',
    backgroundImage: `url(${loginPicture})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',

    left: 0
  }
}))

export const Right = styled(HalfContainer)(({ theme }) => ({
  background: `url(${Triangles}) left bottom no-repeat`,
  backgroundSize: '50%',

  right: 0,
  backgroundColor: theme.palette.primary.main,
  width: '100%'
}))

export const TextContainer = styled('div')(() => ({
  backgroundColor: 'rgba(173,173,173,.61)',
  bottom: 100,
  left: 0,
  position: 'absolute',

  padding: 15,
  width: '100%',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}))

export const TextContent = styled(Typography)(() => ({
  color: '#fff',
  fontSize: 28,
  fontWeight: 500,
  textAlign: 'center'
}))

const Login = () => {
  const { messages } = useMessagesQuery()
  const { messagesHeight, messagesRef } = useMessagesHeight({
    messages
  })

  return (
    <div>
      {console.log('test automatic publish: a really creative comment')}
      <Messages messages={messages} messagesRef={messagesRef} />
      <Left customTop={messagesHeight}>
        <TextContainer>
          <TextContent>
            Empowering Teachers to Give Students What They Really Need. Time.
          </TextContent>
        </TextContainer>
      </Left>
      <Right customTop={messagesHeight}>
        <LoginContainer />
      </Right>
    </div>
  )
}

export default Login
